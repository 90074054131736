const Moment = require('moment-timezone')

const MOMENT_DATE_PATTERN = 'YYYY-MM-DD';

let signInActive = false;
let signInStart = 0;


//account here is coming from MSAL browser component, however we have userName in our workload object
//it would be consistent to use the same object everywhere
export default ({getAuthToken, signIn, account}) => {

    const innerFetch = async (url, conf, attempt = 0) => {
        if (! conf) {
            conf = {}
        }
        if (! conf.headers) {
            conf.headers = {}
        }
        const token = await getAuthToken();
        conf.headers['Authorization'] = 'Bearer ' + token.accessToken
        return fetch(url, conf).then((res) => {
            if (res.status == 401) {
                if (attempt == 5) {
                    console.error("Giving up trying to authorize.")
                    return
                }
                console.log("will try to refresh token now...")
                if (signInActive) {
                    return new Promise((resolve, reject) => {
                        setTimeout(() => {
                            innerFetch(url, conf, attempt + 1).then(resolve).catch(reject);
                        }, 5000);
                    })
                } else {
                    signInActive = true;
                    signInStart = new Date().getTime();
                    return signIn(false).then(() => {
                        signInActive = false;
                        return new Promise((resolve, reject) => {
                            setTimeout(() => {
                                innerFetch(url, conf, attempt + 1).then(resolve).catch(reject);
                            }, 1000);
                        })
                    })
                    .catch((e) => {
                        console.error("Error signing in", e)
                    })
                }
            }
            return res
        })
    }

    const get = async(url) => {
        return innerFetch('/reporting/reports', {
            headers: { "Content-Type": "application/json; charset=utf-8" },
            method: 'GET'
        }).then(response => {
            return response.json()
        })
    }
    
    const api = {
    
        assignDealToUser: async(deal_id, user_id, role) => {
            await innerFetch(`/rest/deals/${deal_id}/assignment`, {
                //TODO remove heades code repetition
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'POST',
                body: JSON.stringify({
                  user_id,
                  role
                })
            })        
            return true
        },

        createDeal: async(type, {caption, dueDate, epic, reviewer, pm, ba, sa, location, customer}) => {
            const formData  = new FormData();
            formData.append("type", type)
            if (type === "Tracking") {
                formData.append("tags", ["tracking"]);
            }
            formData.append("caption", caption)
            if (epic) {
                formData.append("epic", epic)
            }
            if (dueDate) {
                formData.append("dueDate", Moment(dueDate).format(MOMENT_DATE_PATTERN))
            }
            if (reviewer) {
                formData.append("reviewer", reviewer);
            }
            if (pm) {
                formData.append("pm", pm);
            }
            if (ba) {
                formData.append("ba", ba);
            }
            if (sa) {
                formData.append("sa", sa);
            }
            if (location) {
                formData.append("location", location.join(","));
            }
            if (customer) {
                formData.append("customer", customer);
            }
            await innerFetch(`/rest/deals`, {
                method: 'POST',
                body: formData
            })        
            return true
        },

        removeUserFromDeal: async(deal_id, user_id, role) => {
            await innerFetch(`/rest/deals/${deal_id}/assignment`, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'DELETE',
                body: JSON.stringify({
                  user_id,
                  role
                })
            })        
            return true
        },
    
        getDeals: async () => {
            return innerFetch('/rest/workload')
                .then(function(response) {
                    return response.json();
                })
        },
    
        getChanges: async () => {
            return innerFetch(`/rest/workload/changes`)
                .then(function(response) {
                    return response.json();
                })
        },

        getLog: async (skip, limit) => {
            return innerFetch(`/rest/workload/changes/log?` + new URLSearchParams({skip, limit}))
            .then(function(response) {
                return response.json();
            })
        },

        saveToJira: async () => {
            return innerFetch(`/rest/workload/changes`, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'POST'
            }).then((response) => {
                return response.json()
            })
        },
    
        cancelChanges: async () => {
            await innerFetch(`/rest/workload/changes`, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'DELETE'
            })        
            return true        
        },
    
        undo: async () => {
            await innerFetch(`/rest/workload/changes/last`, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'DELETE'
            })        
            return true        
        },
    
        /**
         * Supported fields: duedate
         */
        updateDealDetails: async (deal_id, fields) => {
            await innerFetch(`/rest/deals/${deal_id}/fields`, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'PUT',
                body: JSON.stringify(fields)
            })        
            return true
        },
    
        deleteDeal: async (deal_id) => {
            await innerFetch(`/rest/deals/${deal_id}`, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'DELETE'
            })        
            return true
        },

        addCommands: async (commands) => {
            await innerFetch(`/rest/workload/commands`, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'POST',
                body: JSON.stringify(commands)
            })        
            return true
        },

        addTag: async (dealId, tagName) => {
            await innerFetch(`/rest/deals/${dealId}/tags/${tagName}`, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'PUT'
            })        
            return true
        },

        removeTag: async (dealId, tagName) => {
            await innerFetch(`/rest/deals/${dealId}/tags/${tagName}`, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'DELETE'
            })        
            return true
        },

        getKpiReport: async () => {
            const year = Moment().year();
            return innerFetch(`/reporting/query/quick_kpi?year=${year}`, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'GET'
            }).then(response => {
                return response.json()
            })
        },

        getArchiveReport: async () => {
            return innerFetch(`/reporting/query/archive_queue`, {
                headers: { "Content-Type": "application/json; charset=utf-8" },
                method: 'GET'
            }).then(response => {
                return response.json()
            })
        },

        getReports: async () => {
            return get("/reporting/reports")
        },

        generateReport_html: async (name, params = {}) => {
            return innerFetch(`/reporting/report/${name}?` + new URLSearchParams(params), {method: "GET"})
            .then(response => {
                return response.text()
            })
        },

        generateReport_xls: async (name, params) => {

            const fnGetFileNameFromContentDispostionHeader = (header) => {
                const contentDispostion = header.split(';');
                const fileNameRe = /filename=[\"]{0,1}([^"]+)[\"]{0,1}/
            
                let fileName = 'download.xlsx';
                for (let thisValue of contentDispostion) {
                    const m = thisValue.trim().match(fileNameRe)
                    if (m) {
                        fileName = decodeURIComponent(m[1]);
                        break;
                    }
                }
                return fileName;
            };

            return innerFetch(`/reporting/report/${name}`, {method: "GET"})
            .then(async (res) => {
                const filename = fnGetFileNameFromContentDispostionHeader(res.headers.get('content-disposition'))
                return {
                    filename,
                    blob: await res.blob()
                }
            })
            .then(resObj => {
                // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
                const newBlob = new Blob([resObj.blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
                // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                } else {
                    // For other browsers: create a link pointing to the ObjectURL containing the blob.
                    const objUrl = window.URL.createObjectURL(newBlob);
        
                    let link = document.createElement('a');
                    link.href = objUrl;
                    link.download = resObj.filename;
                    link.click();
        
                    // For Firefox it is necessary to delay revoking the ObjectURL.
                    setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
                }
            })
            .catch((error) => {
                console.log('DOWNLOAD ERROR', error);
            });
        },

        getPersonalSetting: async() => {
            //TODO the following list must be a server-stored list in the user personal settings
            const EMEATeam = ['Volha_Dashkevich@epam.com', 
                'Anastasiia_Razriadova@epam.com', 
                'Ian_Shaw@epam.com',
                'Kateryna_Sereda@epam.com',
                'Maryia_Khadasevich@epam.com',                
                'Ruslan_Prosolovskyi@epam.com', 
                'Dmytro_Shevchenko3@epam.com',
                'Olga_Stepanova@epam.com',
                'Tatsiana_Kuzmina@epam.com',
                'Andrii_Chebotarov@epam.com',
                'Tatyana_Filitaryna@epam.com',
                'harpreet_dulai@epam.com',
                'Elena_Sysoi@epam.com'
            ]

            const NATeam = [
                'Tatsiana_Dvorkina@epam.com',
                'Elena_Baranova@epam.com', 
                'Andrei_Patapenka@epam.com', 
                'Tatsiana_Dvorkina@epam.com',
                'Viktoryia_Kasinskaya@epam.com', 
                'Oleksii_Grushovyi@epam.com',
                'Holly_Quinones@epam.com', 
                'Molly_Brennan@epam.com',
                'Evgenii_Vtorushin@epam.com',
                'Ilya_Antipin@epam.com',
                'Artsiom_Yemelyanenka@epam.com',
                'Zachary_Kniebel@epam.com',
                'Kelly_Brennan@epam.com',
                'Vitalii_Gervaziuk@epam.com',
                'Kate_Pamazai@epam.com',
                'Ernesto_Del_Rosario_Serdiuk@epam.com', 
                'Vadym_Baranenko@epam.com',
                'Oleksandr_Telegin@epam.com',
                'Aliaksandr_Luzgin@epam.com',
                'Anastasiya_Zaleskaya@epam.com',
                'Igor_Makarychev@epam.com',
                'Yulya_Korots@epam.com',
                'Lili_Sichinava@epam.com',
                'anna_boitsova@epam.com',
                'Heather_Herring@epam.com',
                'Vickie_Bertini@epam.com',
                'Marshall_Levin@epam.com'
            ]

            const userId = account.userName
            let userTeam
            if (EMEATeam.indexOf(userId) != -1) {
                userTeam = EMEATeam
            } else {
                userTeam = NATeam
            }

            const calendarMyTeamOnly = localStorage.getItem("calendarMyTeamOnly") == "true" || false
            const calendarAllBAs = calendarMyTeamOnly ? localStorage.getItem("calendarAllBAs") == "true" : false
            const calendarAllSAs = calendarMyTeamOnly ? localStorage.getItem("calendarAllSAs") == "true" : false
            const myTeam = userTeam

            console.log("Loaded personal settings for " + userId)
            return {
                calendarMyTeamOnly, calendarAllBAs, calendarAllSAs, userId, myTeam
            }
        },

        setPersonalSettings: async(settings) => {
            const keys = Object.keys(settings)
            for (let k of keys) {
                localStorage.setItem(k, settings[k])
            }
        }

    }

    return api
}
